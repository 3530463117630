<template>
  <div>
    <action-choicer-dialog v-if="isChoicerVisible" :action-data="actionDataToChoicerDialog"
                           @choiserClose="onChoicerClose"></action-choicer-dialog>

    <div v-if="isListComponent">
      <v-list-item v-for="(btn, index) in actionButtons" :key="index">
        <v-list-item-title>
          <div style="padding-left: 10px" v-if="btn['action'] === 'active_toggle'">
            <v-switch
                disabled
                v-model="isDeviceActive"
                :label="`${isDeviceActive ? 'Активний' : 'Неактивний'}`"
            ></v-switch>
          </div>
          <v-btn
              v-else
              outlined
              elevation="4"
              :loading="btn.isLoading"
              :disabled="btn.isLoading || isSomeActionProcess"
              :color="btn.color"
              @click="openChoicer(btn)"
          >
            {{ btn.text }}
          </v-btn>
        </v-list-item-title>
      </v-list-item>
    </div>

    <v-btn v-else v-for="(btn, index) in actionButtons" :key="index"
           style="margin: 5px"
           outlined
           elevation="4"
           :loading="btn.isLoading"
           :disabled="btn.isLoading || isSomeActionProcess"
           :color="btn.color"
           @click="openChoicer(btn)"
    >
      {{ btn.text }}
    </v-btn>
  </div>
</template>

<script>
import apiService from "@/apiServices/apiService";
import ActionChoicerDialog from "@/components/FireBaseActions/ActionChoicerDialog.vue";
import {kioskActionButtons, androidActionButtons} from "@/utils/actionsButtonList";


export default {
  name: "DeviceActionsComponent",
  components: {ActionChoicerDialog},
  props: {
    device: {
      type: Object,
      default: function () {
        return {isActive: false, type: 'KIOSK', firebaseToken: null, deviceId: null}
      }
    },
    isListComponent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDeviceActive: this.device.isActive,
      isSomeActionProcess: false,
      isChoicerVisible: false,
      actionDataToChoicerDialog: {},
      firebasePayload: null
    }
  },

  methods: {

    closeMenuList() {
      this.$emit('onClickAndFinish')
    },

    onChoicerClose() {
      this.isChoicerVisible = false;
      this.closeMenuList()

    },
    openChoicerModal(btn) {
      this.actionDataToChoicerDialog = btn
      this.actionDataToChoicerDialog.firebaseToken = this.device.firebaseToken
      this.actionDataToChoicerDialog.deviceId = this.device.deviceId
      this.isChoicerVisible = true
    },
    confirmAction(action) {
      if (action === 'deactivation' || action === 'update_app') {
        if (action === 'update_app') {
          this.newAppUrlPrompt()
        }
        return confirm('‼️Ви дійсно упевнені щодо: ' + action + ' ❓')
      }
      return true
    },

    newAppUrlPrompt(){
      const newAppUrl = prompt('Введіть URL нового додатку (або можна пропустити)')
      if(newAppUrl){
        console.log('[i] New app URL: ', newAppUrl)
        this.firebasePayload = { newAppUrl:  String(newAppUrl).trim() }
      }else {
        console.log('[❌] New app URL is empty')
      }
    },

    openChoicer(btn) {
      this.isChoicerVisible = false
      console.log('Open choicer: ', btn)
      switch (btn.action) {
        case 'update_bg':
        case 'update_game_config':
          this.openChoicerModal(btn)
          break;
        default:
          this.sendFirebaseActionMsg(btn);
          break
      }
    },

    cancelBtnAction(btn) {
      btn['isLoading'] = false;
      this.isSomeActionProcess = false;
      this.firebasePayload = null;
      this.closeMenuList()
    },

    async sendFirebaseActionMsg(btn) {
      console.log('[i] sendFirebaseActionMsg: ', btn)
      this.isSomeActionProcess = true;
      if (this.confirmAction(btn['action'])) {
        try {
          btn['isLoading'] = true
          const body = {action: btn.action}
          if (this.firebasePayload) {
            body['payload'] = this.firebasePayload
          }

          if(this.device.type.toUpperCase() === 'KIOSK'){
            if(this.device.firebaseToken){
              body.tokens = new Array(this.device.firebaseToken)
            }else{
              if(this.isListComponent){
                this.$store.commit('setSnackMessage', {
                  message: 'Помилка: Немає токену для відправки повідомлення',
                  isError: true
                })
                this.closeMenuList()
                return false
              }else{
                body.topic = 'royal_kiosks'
              }
            }
          }


          this.$store.commit('setSnackMessage', {
            message: `Виконую: ${btn.text}...`, timeout: 1100
          })
          const fbMsgResponse = await apiService.sendFirebasePushMsg(body)
          console.log(fbMsgResponse)
          if (!fbMsgResponse['success']) {
            alert(`🔴 ERROR\n${fbMsgResponse['error']}`)
          } else {
            this.$store.commit('setSnackMessage', {
              message: `✅ ${btn.text} виконано`
            })
          }
        } catch (e) {
          alert(`🔴 ERROR\n${e}`)
        } finally {
          this.$store.commit('clearSnackMessage')
          this.cancelBtnAction(btn)
        }
      }else {
        this.cancelBtnAction(btn)
      }
    },
  },
  computed: {
    actionButtons() {
      return this.device.type.toUpperCase() === 'KIOSK' ? kioskActionButtons : androidActionButtons
    }
  }
}
</script>

<style scoped>

</style>