<template>
  <!--  <div>-->
  <!--    <apexchart width="800" type="bar" :options="chartOptions" :series="series"></apexchart>-->
  <!--  </div>-->
  <!--  <apexchart type="donut"  :options="chartOptions" :series="series"></apexchart>-->
  <v-container fluid>
    <v-row dense>
      <v-col
          v-for="(card, index) in chartsCards"
          :key="index"
          :cols="card.flex"
      >
        <v-card>
          <v-card-title>{{ card.title }}</v-card-title>
          <apexchart :type="card.type" :options="genChartOptions(card.type, card.transactionType)"
                     :series="genSeries(card.type, card.transactionType)"></apexchart>
          <!--          <apexchart type="donut"  :options="chartOptions" :series="series"></apexchart>-->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "MainChartsComponent",
  isChartMultiLine: false,
  props: {
    data: {
      type: Array,
      required: true
    },
    chartsCards:{
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
    }
  },
  methods: {

    parseTimestamp (timestamp){
      // Якщо це число — Unix timestamp у секундах
      if (typeof timestamp === 'number') return new Date(timestamp * 1000);

      // Якщо це рядок і валідна дата у форматі ISO
      const date = new Date(timestamp);
      return !isNaN(date) ? date : null;
    },

    genSeries(chartType, transactionType) {
      switch (chartType) {
        case 'pie':
        case 'donut':
          return transactionType === 'deposit' ? this.chartsDonutData.deposits : this.chartsDonutData.withdrawals
        case 'line':
          return this.chartLineDataMethod(transactionType)
          // console.log('[i] Chart line Data Series: ', this.chartLineData)
          // return this.chartLineData
      }
    },

    genChartOptions(chartType, transactionType) {
      switch (chartType) {
        case 'pie':
        case 'donut':
          return {
            labels: this.chartsDonutData.labels,
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      showAlways: true,
                      show: true
                    }
                  }
                }
              }
            }
          }
        case 'line':
          console.log('[i] Draw line chart options')
          // console.log('Category: ', this.data.map(entry => entry.timestamp))
          if(!this.isChartMultiLine){
            return {
              dataLabels: {
                enabled: false
              },
              markers: {
                size: 2
              },
              height: 250,
              stroke: {
                curve: 'straight'
              },
              title: {
                text: transactionType === 'deposit' ? 'Лише поповнення' : 'Лише списання',
                align: 'left'
              },
              xaxis: {
                type: 'datetime'
              },
            }
          }

      }
    },

    genMultiLineChartData(){
      const lineChart = []
      this.data.forEach((entry) => {
        const game = entry.description || 'N/A';
        const gameEntry = lineChart.find(item => item.name === game);
        if (gameEntry) {
          gameEntry.data.push({x: entry.timestamp, y: entry.amount});
        } else {
          lineChart.push({ name: game, data: [{x: entry.timestamp, y: entry.amount}] });
        }
      });
      return lineChart;
    },

    chartLineDataMethod(transactionType) {
      const isDeposit = transactionType === 'deposit';

      const data = this.data
          .filter(entry => isDeposit ? entry.amount > 0 : entry.amount < 0)
          .map(entry => ({
            x: this.parseTimestamp(entry.timestamp),
            y: isDeposit ? entry.amount : Math.abs(entry.amount)
          }));

      // console.log('[i] Simple line chart data from Method: ', JSON.stringify(data, null, 2));

      return [{
        name: transactionType === 'deposit' ? 'поповнення' : 'списання',
        data
      }];

    },
  },
  computed: {

    chartLineData() {
      if(this.isChartMultiLine){
        return this.genMultiLineChartData()
      }else{
        const data = []
        this.data.forEach(entry => {
          if(entry.amount > 0 && data.length < 100){
            data.push({
              x: this.parseTimestamp(entry.timestamp), //new Date(entry.timestamp * 1000),
              y: entry.amount
            })
          }

        })
        // console.log('[i] Simple line chart data: ', JSON.stringify(data, null, 2))

        return [{
          name: 'deposits',
          data: data
        }]
      }

    },
    chartsDonutData() {
      const gameStats = this.data.reduce((acc, entry) => {
        const game = entry.description ? entry.description : 'N/A';
        if (!acc[game]) {
          acc[game] = {deposits: 0, withdrawals: 0};
        }

        if (entry.amount < 0) {
          acc[game].withdrawals += Math.abs(entry.amount);
        } else {
          acc[game].deposits += entry.amount;
        }

        return acc;
      }, {});
      return {
        labels: Object.keys(gameStats),
        deposits: Object.values(gameStats).map(game => game.deposits),
        withdrawals: Object.values(gameStats).map(game => game.withdrawals),
      }
    }
  }
}
</script>


<style scoped>

</style>