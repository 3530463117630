<template>

  <v-dialog
      v-model="newDeviceDialog"
      persistent
      max-width="600px"
  >
    <v-snackbar
        v-model="snackbar"
        color="warning"
        absolute
        top
        right
        elevation="24"
        centered
        text
        timeout="3500">
      Заповніть обов'язкові дані
      <template v-slot:action="{ attrs }">
        <v-btn
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-card>
      <v-progress-linear v-if="loading"
                         :active="loading"
                         :indeterminate="loading"
                         height="6px"
      ></v-progress-linear>
      <div v-if="loading" style="padding-top:20px; text-align: center">Очікуйте...

      </div>
      <v-card-title>
        <span class="text-overline mb-lg-7">Новий девайс: <span style="color: #26bf26" v-if="success">{{device.deviceId}}</span> {{success ? 'успішно створено' : ''}}</span>
      </v-card-title>
      <v-card-text>
        <v-form
            ref="form"
            lazy-validation
        >
          <v-radio-group
              @change="generateId"
              v-model="device.type"
              row
          >
            <v-radio
                label="Android"
                value="MOBILE_CASHIER"
            ></v-radio>
            <v-radio
                label="Poster"
                value="POSTER"
            ></v-radio>
            <v-radio
                label="Kiosk"
                value="KIOSK"
            ></v-radio>
          </v-radio-group>
          <v-col>
            <v-text-field
                :disabled="success"
                v-model="device.deviceId"
                :counter="19"
                label="Id девайсу"
                required
                append-icon="mdi-numeric"
                @click:append="generateId"
            ></v-text-field>
          </v-col>


          <v-select
              v-model="device.locationId"
              :items="getAllAvalibleData"
              :item-text="'locationName'"
              :item-value="'locationId'"
              label="Локація*"
              :disabled="success"
              required
          >

          </v-select>
          <v-text-field
              v-model="device.comment"
              label="Коментар"
              :disabled="success"
          ></v-text-field>


          <v-btn
              :disabled="!valid || loading || success"
              color="primary"
              class="mr-4"
              @click="createDevice"

          >
            Створити
          </v-btn>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mr-4"
                  v-bind="attrs"
                  v-on="on"
                  @click="generateQR"
              >
                <v-icon>
                  mdi-qrcode
                </v-icon>
              </v-btn>
            </template>
            <span>Генерувати QR</span>
          </v-tooltip>


        </v-form>
        <div v-if="isNeedQr" style="text-align: center;padding: 20px;">
          <qrcode-vue :value="device.deviceId" size="220" level="H"/>
        </div>
        <small v-if="isNeedQr">*Qr код для сканування на девайсі!</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :disabled="loading"
            color="blue darken-1"
            text
            @click="closeModal"
        >
          Закрити
        </v-btn>

      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import apiService from "@/apiServices/apiService";

export default {
  name: "CreateNewDevice",
  components: {QrcodeVue},
  props: {
    value: Boolean,
    avalibleLocations: Array
  },
  data: () => {
    return {
      snackbar: false,
      valid: true,
      loading: false,
      isNeedQr: false,
      success: false,
      device: {deviceId: '', comment: '', locationId: '', type: null},
    }
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
   async createDevice() {

      if (this.device.deviceId.length > 3 && this.device.locationId.length != 0) {
        this.loading = true;
        try {
          console.log('New Device to create ->>> ', this.device)
          const createDeviceResponse = await apiService.createDevice(this.device);
          console.log('createDeviceResponse -->> ', createDeviceResponse)
          if(createDeviceResponse.success){
            this.loading = false;
            this.success = true;
          }else{
            this.$store.commit('setSnackMessage', {
              message: createDeviceResponse.error,
              isError: true
            })
            this.loading = false;
          }
        }catch (e){
          this.loading = false;
          this.$store.commit('setSnackMessage', {
            message: e,
            isError: true
          })
        }

      } else {
        this.snackbar = true;
      }

    },
    closeModal() {
      this.reset();
      this.$store.commit("changeUpdDeviceList", !this.$store.getters.isNedUpdDeviceList);
      this.newDeviceDialog = false;

    },
    generateId() {
      if(this.device.type){
        const id = `id${this.device.type === 'KIOSK' ? 'K' : ''}_` + (Math.random() + 1).toString(36).substring(2);
        this.device.deviceId = id;
        console.log('gen id ', id);
      }else {
        this.snackbar = true;
      }
    },
    generateQR() {
      console.log('gen qr')
      if (this.device.deviceId.length > 3) {
        this.isNeedQr = !this.isNeedQr
      } else {
        this.snackbar = true;
      }

    }
  },
  computed: {
    newDeviceDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    getAllAvalibleData: {
      get() {
        console.log('inn computed NEW DEVICE dta -> ', this.avalibleLocations)
        return this.avalibleLocations
      }
    }

  },
}
</script>

<style scoped>
.loader {
  position: inherit;
}

</style>
