<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card flat>
      <v-card-title>Редагування Конфігу [ {{ deviceId }} ]
        <v-spacer></v-spacer>
<!--        <v-tooltip top>-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <v-btn color="primary" v-bind="attrs" v-on="on" icon @click="fixJsonTextField">-->
<!--              <v-icon>mdi-code-json</v-icon>-->
<!--            </v-btn>-->
<!--          </template>-->
<!--          <span>prettify</span>-->
<!--        </v-tooltip>-->
        <copy-to-clipboard-component hint-text="конфіг" :value="JSON.stringify(jsonConfig)"></copy-to-clipboard-component>
      </v-card-title>
      <v-card-text>
        <div v-if="!deviceId">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Копіювати з девайсу
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                    align-content="space-between"
                    justify="start"
                    style="padding: 10px 0 10px 0"
                >
                  <v-btn v-for="(device, index) in devices" :key="index"
                         style="margin: 5px"
                         outlined
                         elevation="4"
                         @click="loadConfig(device.deviceId)"
                  >
                    {{ device.deviceId }}
                  </v-btn>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <br></div>
<!--        <v-textarea-->
<!--            v-model="jsonConfig"-->
<!--            label="Конфігурація гри"-->
<!--            outlined-->
<!--            rows="18"-->
<!--        ></v-textarea>-->
        <vue-json-editor v-model="jsonConfig" :show-btns="false" :expandedOnStart="false" @json-change="onJsonChange" @has-error="onJsonError" ></vue-json-editor>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :disabled="isButtonDisabled"
            color="primary"
            text
            @click="closeDialog">
          Скасувати
        </v-btn>
        <v-btn
            :disabled="isSaveButtonDisabled"
            :loading="isButtonDisabled"
            color="primary"
            text
            @click="saveConfig"
        >
          Зберегти
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>


<script>
import apiService from "@/apiServices/apiService";
import CopyToClipboardComponent from "@/components/CopyToClipboardComponent.vue";
import vueJsonEditor from 'vue-json-editor'


export default {
  name: "GameConfigDialog",
  components: { CopyToClipboardComponent, vueJsonEditor},
  props: {
    deviceId: String,
    firebaseToken: String
  },
  data() {
    return {
      isButtonDisabled: false,
      isSaveButtonDisabled: false,
      dialog: true,
      jsonConfig: null,
      devices: []
    }
  },

  methods: {

    onJsonChange(json) {
      this.jsonConfig = json
      this.isSaveButtonDisabled = false
      console.log('json changed', this.jsonConfig)
    },

    onJsonError(error) {
      console.log('json error', error)
      this.isSaveButtonDisabled = true
    },

    closeDialog() {
      this.$emit('on-game-config-closed')
    },
    fixJsonTextField() {
      try {
        this.jsonConfig = this.prettyJSON(JSON.parse(this.jsonConfig))
      } catch (e) {
        alert('🔴Некоректний JSON\n\n' + e)
      }

    },
    prettyJSON(value) {
      return value //JSON.stringify(value, null, 2);
    },
    async loadConfig(deviceId) {
      if (deviceId) {
        try {
          const configResponse = await apiService.getGameConfigs(deviceId)
          console.log(configResponse)
          if (configResponse.success) {
            this.jsonConfig = this.prettyJSON(configResponse.configs)
          } else {
            alert(configResponse.error)
          }
        } catch (e) {
          alert(e)
        }
      }else{
        try {
          const devicesResponse = await apiService.getDevices()
          console.log(devicesResponse)
          if (devicesResponse.success) {
            this.devices = devicesResponse.devices.filter(device => device.type === 'KIOSK')
          } else {
            alert(devicesResponse.error)
          }
        }catch (e) {
          alert(e)
        }
      }

    },
    async saveConfig() {
      try {
        JSON.parse(JSON.stringify(this.jsonConfig))
      } catch (e) {
        alert('🔴Некоректний JSON\n\n' + e)
        return
      }

      this.isButtonDisabled = true
      this.isSaveButtonDisabled = true
      try {
        const uploadConfigResponse = await apiService.uploadGameConfig(this.deviceId, this.jsonConfig)
        console.log(uploadConfigResponse)
        if (uploadConfigResponse.success) {
          alert('✅ Конфігурація збережена')
          const confirm = window.confirm('Оновити на девайсі?')
          if (confirm) {
            try{
              const body = {action: 'update_game_config'}
              if(this.deviceId && this.firebaseToken){
                body['tokens'] = new Array(this.firebaseToken)
              } else {
                body['topic'] = 'royal_kiosks'
              }
              const fbMsgResponse = await apiService.sendFirebasePushMsg(body)
              console.log(fbMsgResponse)
              if (!fbMsgResponse['success']) {
                alert(`🔴 ERROR Firebase action\n${fbMsgResponse['error']}`)
              } else {
                this.closeDialog()
              }
            }catch (e) {
              alert('🔴'+e)
            }
          }else {
            this.closeDialog()
          }

        } else {
          alert(uploadConfigResponse.error)
        }
      } catch (e) {
        alert(e)
      } finally {
        this.isButtonDisabled = false
        this.isSaveButtonDisabled = false
      }
    },
  },
  mounted() {
    console.log('GameConfigDialog mounted')
    this.loadConfig(this.deviceId)

  }
}
</script>
<style scoped>

</style>