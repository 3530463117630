<template>
  <v-row class="pa-10">
    <v-col
        md="3"
    >
      <div class="pa-1" v-if="hideSelect">
        <v-card>
          <v-card-text>
            <p>
              <v-icon color="primary">mdi-hospital-building</v-icon>
              <span style="margin-left: 10px"><b>{{ selectedLocation }}</b></span>
            </p>


          </v-card-text>
          <v-card-actions>
            <span>{{ selectedDate.map(dt => formatDate(dt)).join(' - ') }}</span>
            <v-spacer></v-spacer>
            <div style="padding-bottom: -10px; ">

              <v-btn
                  icon
                  @click="hideSelect = !hideSelect"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </div>
          </v-card-actions>

        </v-card>
      </div>
      <div v-if="!hideSelect">
        <v-select
            v-model="selectedLocation"
            :items="locationsWithCashiers"
            label="Оберіть зал"
            prepend-icon="mdi-map"
            @change="selectVenueHandler"

        >


        </v-select>

<!--        <v-select-->
<!--            v-model="selectedCashier"-->
<!--            :items="cashiersList"-->
<!--            :item-text="'cashier_name'"-->
<!--            :item-value="'cashier_id'"-->
<!--            :disabled="cashiersList.length < 1"-->
<!--            label="Оберіть касира"-->
<!--            hint="Можна обрати кілька"-->
<!--            multiple-->
<!--            chips-->
<!--            prepend-icon="mdi-account"-->
<!--            @change="selectCashiersHandler"-->
<!--        >-->

<!--        </v-select>-->

        <v-menu
            ref="menu"
            v-model="datePickerMenu"
            :close-on-content-click="false"
            :return-value.sync="selectedDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDateFormatted"
                label="Оберіть дату"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on">

            </v-text-field>
          </template>
          <v-date-picker
              v-model="selectedDate"
              range
              :first-day-of-week="1"
              locale="uk-Ua"
              @change="compareDays"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="datePickerMenu = false"
            >
              Відміна
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(selectedDate)"
            >
              OK
            </v-btn>
          </v-date-picker>

        </v-menu>
        <div class="text-center">
          <v-row class="justify-center">
            <v-btn
                class="ma-2"
                :loading="loading"

                color="success"
                @click="loadNewTransactions"
            >
              Завантажити
              <template v-slot:loader>
                <span>Завантаження...</span>
              </template>
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <change-shift-time-dialog-component></change-shift-time-dialog-component>
                    </span>
              </template>
              <span>Змінити операційний час</span>
            </v-tooltip>
          </v-row>
        </div>
      </div>

      <div v-if="sumarry.totalOperations >= 1 " style="margin-top: 50px">
        <transaction-summary-card @chartToggled="toggleChartsCard" type="tickets" is-chart-toggle-visible :date-range="selectedDate" :summary="sumarry" :xls="{data: transactions, column: tableHeaders}"></transaction-summary-card>
        <v-divider></v-divider>
      </div>


    </v-col>


    <v-col
        md="9"

    >

      <div style="text-align: center;" v-if="sumarry.totalOperations === 0 && !loading">
        <v-img style="margin:auto; margin-top: 50px" :src="require('@/assets/royal_logo.png')"
               max-width="200px"
        ></v-img>
        <p style="margin-top: 50px">Дані ще не завантажнно або відсутні за даний період</p>
      </div>
      <v-card v-if="sumarry.totalOperations >= 1 && !chartToggle">
        <v-card-title>
          <v-text-field
              v-model="searchOnTable"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="tableHeaders"
            :items="transactions"
            :search="searchOnTable"

            :footer-props="{
        showFirstLastPage: true,
           'items-per-page-text':'транзакцій на сторінці',

      }"
        >

          <template v-slot:[`footer.prepend`]>
            <div style="display: contents;">
              <small>Операційний день: {{ $store.getters.getShiftTimeStart }} до
                {{ $store.getters.getShiftTimeEnd }} </small>
              <small>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                        <span  v-bind="attrs"  v-on="on" >
                          <change-shift-time-dialog-component></change-shift-time-dialog-component>
                        </span>
                  </template>
                  <span>Натисніть для корегування</span>
                </v-tooltip>
              </small>

            </div>
          </template>

          <template v-slot:[`item.timestamp`]="{ item }">
              <span :class="{ depositTransaction: item.amount > 0}">
                {{
                  new Date(item.timestamp).toLocaleString('uk-UA')
                }}

              </span>
          </template>
          <template v-slot:[`item.playerId`]="{ item }">
              <span :class="{ depositTransaction: item.amount > 0}">
                {{ item.playerId }}

              </span>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
              <span :class="{ depositTransaction: item.amount > 0}">
                {{ item.amount.toLocaleString('uk-UA') }}

              </span>
          </template>

          <template v-slot:[`item.cashier`]="{ item }">
            <v-icon :color="item.amount > 0 ? 'success' : ''">mdi-account-circle</v-icon>
            {{ `  ` }}{{ item.cashier }}
          </template>
        </v-data-table>
      </v-card>
      <v-card v-if="loading">
        <v-skeleton-loader
            :loading="false"
            type="table-tbody"
        ></v-skeleton-loader>
      </v-card>


      <v-card v-if="chartToggle"
              class="mt-4 mx-auto"

      >


        <v-card-text class="pt-0">
          <div class="text-h6 font-weight-light mb-2">
            Графік операцій
          </div>
          <main-charts-component :data="transactions" :charts-cards="chartCards"></main-charts-component>
<!--          <div class="subheading font-weight-light grey&#45;&#45;text">-->
<!--            В процесі розробки... колись тай буде 🤞-->
<!--          </div>-->
          <v-divider class="my-2"></v-divider>
          <v-icon
              class="mr-2"
              small
          >
            mdi-clock
          </v-icon>
          <span
              class="text-caption grey--text font-weight-light">За обраний період {{
              selectedDate.map(dt => formatDate(dt)).join(' - ')
            }}</span>
        </v-card-text>
      </v-card>


    </v-col>
  </v-row>

</template>

<script>
import TransactionSummaryCard from "@/components/MainPageComponents/TransactionSummaryCard.vue";
import ChangeShiftTimeDialogComponent from "@/components/ChangeShiftTimeDialogComponent.vue";
import apiService from "@/apiServices/apiService";
import store from "@/store";
import MainChartsComponent from "@/components/Charts/MainChartsComponent.vue";

export default {
  name: "TicketsHistory",
  components: {MainChartsComponent, TransactionSummaryCard, ChangeShiftTimeDialogComponent},
  data: () => ({
    chartCards: [
      {title: 'Зняття', flex: 6, type: 'donut', transactionType: 'withdraw'},
      {title: 'Нарахування', flex: 6, type: 'donut', transactionType: 'deposit'},
    ],
    snackbar: {isVisible: false, text: 'Очікуйте завантаження...'},
    excelDownloadLink: null,
    hideSelect: false,
    totalView: false,
    datePickerMenu: false,
    selectedDate: new Array((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
    sumarry: {
      totalAmount: 0,
      totalOperations: 0,
      totalWithdrawCounter: 0,
      totalDepositsCounter: 0,
      totalWithdrawAmount: 0,
      totalDepositsAmount: 0
    },
    selectedLocationName: '',
    selectedCashiersNames: [],

    transactionsMetaData: {},
    sumarryByCashiers: {},


    chartToggle: false,
    loading: false,
    selectedLocation: '',
    selectedCashier: '',
    searchOnTable: '',
    tableHeaders: [
      {text: 'Дата/Час', value: 'timestamp'},
      {text: 'PlayerID', value: 'playerId'},
      {text: 'ПІБ', value: 'playerName'},
      {text: 'К-сть квитків', value: 'amount'},
      {text: 'Касир', value: 'cashier'},
      {text: 'Опис', value: 'description'},
    ],
    locations: [],
    cashiers: [],
    transactions: [],

    cashiersList: [],
    filteredItemsData: [],

    locationsWithCashiers: [],


  }),
  methods: {


    toggleChartsCard(chartToggle) {
      this.chartToggle = chartToggle;
    },

    async getAllowedDates() {
      const data = await apiService.getUniqueLocationsTickets();
      console.log('Cashiers and adm in POINTS view -> ', data)
      if(data['success']){
        this.locationsWithCashiers = data.locations;
      }else{
        this.$store.commit('setSnackMessage', {
          message: data['error'],
          isError: true
        })
      }

    },


    filterItemsData(val, data) {
      console.log(val, data)
      if (val) {
        this.filteredItemsData[data] = this.transactions.filter(transition => transition.cashier_name === data)
      }
    },

    selectVenueHandler(selectedVal) {
      let convertedFromObserve = JSON.parse(JSON.stringify(this.locationsWithCashiers))
      let cashierList = [];
      convertedFromObserve.forEach(el => {
        if (el.locationId === selectedVal) {
          console.log(el)
          cashierList = el.cashiers;
          this.selectedLocationName = el.locationName;
        }
      })
      this.cashiersList = cashierList;
    },

    selectCashiersHandler(seletedValues) {
      let selectedCashiersName = [];
      this.cashiersList.forEach(el => {
        if (seletedValues.includes(el.cashier_id)) {
          selectedCashiersName.push(el.cashier_name);
        }
      })
      this.selectedCashiersNames = selectedCashiersName;
    },


    async loadNewTransactions() {

      console.log(this.selectedLocation)
      console.log(this.selectedCashier)

      // const startTimeStemp = `08:00:00_${this.selectedDate[0]}`;
      // const endTimeStemp = `07:59:00_${this.selectedDate[1] ? this.selectedDate[1] : this.selectedDate[0]}`;


      if(this.selectedLocation === ''){
        this.$store.commit('setSnackMessage', {
          message: 'Оберіть зал!',
          isError: true
        })
        return false;
      }

      const startTimeStemp = `${this.$store.getters.getShiftTimeStart}_${this.selectedDate[0]}`
      const endTimeStemp = `${this.$store.getters.getShiftTimeEnd}_${this.selectedDate[1] ? this.selectedDate[1] : this.selectedDate[0]}`;

      this.sumarry = {
        totalAmount: 0,
        totalOperations: 0,
        totalWithdrawCounter: 0,
        totalDepositsCounter: 0,
        totalWithdrawAmount: 0,
        totalDepositsAmount: 0
      };
      try {
        this.loading = true;

        const requestData = `?startDate=${startTimeStemp}&endDate=${endTimeStemp}&location=${this.selectedLocation}`;
        //
        this.excelDownloadLink = `${window.location.host.includes('localhost') ? 'https://dev.royalcasino.ua' : ''}/api/transactions_location_excel${requestData}`
        console.log('requestData --> ', requestData);

        let transactions = await apiService.getTicketsTransactions(requestData)
        console.log('response data -> ', transactions)
        if ('msg' in transactions) {
          console.log('msg is pressent')
          return false;
        }
        if(!transactions['success']){
          this.$store.commit('setSnackMessage', {
            message: transactions['error'],
            isError: true
          })
          this.loading = false;
          return false;
        }

        this.transactions = transactions.transactions;
        this.transactionsMetaData = transactions.cashiers;


        this.sumarry.totalOperations = transactions.transactions.length;
        this.sumarry.totalAmount = 0;
        this.sumarry.totalDepositsCounter = 0;
        this.sumarry.totalDepositsAmount = 0;
        this.sumarry.totalWithdrawAmount = 0;

        let sumarryByCashiers = {};
        // transactions.cashiers.forEach(cashierItem => {
        //   sumarryByCashiers[cashierItem.cashier] = {
        //     totalAmount: 0,
        //     totalDepositsCounter: 0,
        //     totalDepositsAmount: 0,
        //     totalWithdrawCounter: 0,
        //     totalWithdrawAmount: 0,
        //     totalOperations: 0,
        //     cashierName: '',
        //     transactionsListTogle: false
        //   };
        // });


        console.log('sumarryByCashiers ---->>>>', sumarryByCashiers)
        transactions.transactions.forEach(transaction => {
          this.sumarry.totalAmount = this.sumarry.totalAmount + transaction.amount;
          // sumarryByCashiers[transaction.cashier]['totalAmount'] = sumarryByCashiers[transaction.cashier]['totalAmount'] + transaction.amount;
          // sumarryByCashiers[transaction.cashier]['totalOperations'] += 1;
          if (transaction.amount < 1) {
            this.sumarry.totalWithdrawCounter += 1;
            this.sumarry.totalWithdrawAmount = this.sumarry.totalWithdrawAmount + Math.abs(transaction.amount);
            // sumarryByCashiers[transaction.cashier]['totalWithdrawCounter'] += 1;
            // sumarryByCashiers[transaction.cashier]['totalWithdrawAmount'] = sumarryByCashiers[transaction.cashier]['totalWithdrawAmount'] + Math.abs(transaction.amount);
          } else {
            this.sumarry.totalDepositsCounter += 1;
            this.sumarry.totalDepositsAmount = this.sumarry.totalDepositsAmount + Math.abs(transaction.amount);
            // sumarryByCashiers[transaction.cashier]['totalDepositsCounter'] += 1;
            // sumarryByCashiers[transaction.cashier]['totalDepositsAmount'] = sumarryByCashiers[transaction.cashier]['totalDepositsAmount'] + Math.abs(transaction.amount);
          }

        })

        this.sumarryByCashiers = Object.assign({}, this.sumarryByCashiers, sumarryByCashiers);
        console.log('sumarryByCashiers -> ', sumarryByCashiers)

        this.loading = false;
        this.hideSelect = true;
      } catch (e) {
        console.log('get transactions error -> ', e);
        this.$store.commit('setSnackMessage', {
          message: e,
          isError: true
        })
        this.loading = false;
      }


    },

    getColor(amount) {
      if (amount < 0) return 'warning'
      else return 'success'
    },

    getArrowIcon(amount) {
      if (amount < 0) return 'mdi-arrow-top-right'
      else return 'mdi-arrow-bottom-left'
    },
    compareDays(val) {
      console.log('in compares -> ', val)
      const startDay = new Date(String(this.selectedDate[0]));
      const endDay = new Date(String(this.selectedDate[1]));
      const strStartDate = this.selectedDate[0];
      const strEndDate = this.selectedDate[1];
      if (startDay > endDay) {
        this.selectedDate[0] = strEndDate;
        this.selectedDate[1] = strStartDate;
      }
      console.log('creconverted date -> ', this.selectedDate)
      // this.$refs.menu.save(this.selectedDate);
    },


    formatDate(dates) {
      console.log(dates, typeof dates)
      if (!dates) return null;
      if (!Array.isArray(dates)) dates = new Array(dates)
      return dates.map(date => {
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
      })

    },

  },
  computed: {
    store() {
      return store
    },
    computedDateFormatted() {
      return this.formatDate(this.selectedDate);
    },
  },

  mounted() {
    this.getAllowedDates()
  }
}
</script>


<style scoped>

.depositTransaction {
  color: green;
}

</style>