import { render, staticRenderFns } from "./MainChartsComponent.vue?vue&type=template&id=62f670a3&scoped=true&"
import script from "./MainChartsComponent.vue?vue&type=script&lang=js&"
export * from "./MainChartsComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62f670a3",
  null
  
)

export default component.exports